import request from '@/utils/request'

export function getCopyRight(data = {}) {
  return request({
    url: '/copyright/',
    method: 'post',
    data
  })
}

export function getStatement(data = {}) {
  return request({
    url: '/copyright/statement',
    method: 'post',
    data
  })
}

